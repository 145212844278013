// Saga actions are dispatched from components requesting async data from Redux.
// See LoginView.tsx for example
export const sagaActions = {

    AUTHENTICATE_SAGA: 'AUTHENTICATE_SAGA',
    ISBANNED_SAGA: 'ISBANNED_SAGA',

    APPLICATIONS_SAGA: 'APPLICATIONS_SAGA',
    APPLICATION_BY_ID_SAGA: 'APPLICATION_BY_ID_SAGA',
    APPLICATION_CONFIGURATION_SAGA: 'APPLICATION_CONFIGURATION_SAGA',

    DEVICES_SAGA: 'DEVICES_SAGA',

    DATAPATHS_SAGA: 'DATAPATHS_SAGA',

    TRENDVALUES_SAGA: 'TRENDVALUES_SAGA',
};
