import React from 'react';
import { history } from '../redux/store';
import Typography from '@material-ui/core/Typography';

// https://reacttraining.com/react-router/web/guides/quick-start
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';
import { IAuthState } from '../redux/reducers/authReducer';
import { connect } from 'react-redux';
import Navigation from './Navigation';
import DevicesView from './DevicesView';
import ApplicationsView from './ApplicationsView';
import DataView from './DataView';
import DashboardsView from './DashboardsView';

/*
interface IAuthState {

    authState: IAuthState|any;
}
*/

interface IComponentProps {

    authState: IAuthState;
}

class MainView extends React.Component<IComponentProps> {

    public constructor(props: IComponentProps) {

        super(props);
    }

    public render() {

        if (!this.props.authState.token) {

            return (<Redirect to='/login'/>);
        }

        return (
            <ConnectedRouter history={history}>
                <Navigation></Navigation>
                <Switch>
                    <Route exact path='/dashboards'>
                        <DashboardsView/>
                    </Route>
                    <Route exact path='/applications'>
                        <ApplicationsView/>
                    </Route>
                    <Route exact path='/devices'>
                        <DevicesView/>
                    </Route>
                    <Route exact path='/networking'>
                        Networking
                    </Route>
                    <Route exact path='/settings'>
                        Settings
                    </Route>
                    <Route exact path='/data'>
                        <DataView/>
                    </Route>
                </Switch>
            </ConnectedRouter>
        );
    }
}

const mapStateToProps = (state: any, ownProps: any): IComponentProps => {
    return { authState: state.authReducer };
};

export default connect(mapStateToProps, null)(MainView);
