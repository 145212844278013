import React from 'react';
import Paper from '@material-ui/core/Paper';

import { Button,
    Box,
    Card,
    CardActions,
    CardContent,
    Grid,
    makeStyles,
    TextField,
    Typography,
    IconButton,
} from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CachedIcon from '@material-ui/icons/Cached';
import { connect } from 'react-redux';
import {
    IApplicationsState,
    IAutomationErrorState,
    IAutomationState,
    ITrendValuesState,
} from '../redux/reducers/automationReducer';

import { Moment } from 'moment';
import { AnyAction, Dispatch } from 'redux';
import { sagaActions } from '../redux/sagas/sagaActions';
import { Sequencer_ApplicationConfigurationDto, TrendValueDto } from '../services/PhoenixAPIClient';
import AngularChart from '../components/AngularChart';
import moment from 'moment';
import { XYChart } from '../components/XYChart';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
      padding: '10px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: '1.5rem',
    },
    pos: {
      marginBottom: 12,
    },
    chart: {

    },
});

type IComponentProps = IApplicationsState & IAutomationErrorState & ITrendValuesState;

interface IDispatchProps {

    getApplicationById: (id: number) => void;
    getApplicationConfiguration: (id: number) => void;
    getTrendData: (startTime: Moment, endTime: Moment, dataPathName: string) => void;
}

type DashboardWateringSystemProps = IComponentProps & IDispatchProps;

export const DashboardWateringSystem = (props: DashboardWateringSystemProps) => {

    const classes = useStyles();

    const config: Sequencer_ApplicationConfigurationDto = props.applicationConfigurations[0] || {};

    const getTrendData = () => {

        if (config?.sensors) {

            props.getTrendData(
                moment().add(-24, 'hours'), // hour(0).minute(0).second(0),
                moment(),
                config.sensors[0],
                );
        }
    };

    // Get Watering system config if not present already
    if ((props.applications == null || props.applications.length === 0 )
        && !props.applicationsLoading
        && props.error == null) {
        props.getApplicationById(1); /* TODO: configure applicationId */
        props.getApplicationConfiguration(1);
    }

    if (props.applicationConfigurations?.length > 0 && !props.trendValuesLoading) {

        const moistureSensor =
            (props.applicationConfigurations?.[0] as Sequencer_ApplicationConfigurationDto).sensors?.[0];
        console.log('Data query', moistureSensor);

        if (!props.trendValuesLoading && (!props.trendValues
            || props.trendValues.length === 0)
        ) {

            getTrendData();
        }
    }

    let currentReservoirLevel: TrendValueDto = new TrendValueDto();
    currentReservoirLevel.id = 1;
    currentReservoirLevel.dataPath = undefined;
    currentReservoirLevel.value = 0;
    currentReservoirLevel.timeStamp = moment().format();

    currentReservoirLevel = props.trendValues?.[props.trendValues.length - 1] || currentReservoirLevel;

    return (
        <div>
            {props.applicationsLoading || props.trendValuesLoading ? <LinearProgress /> : null }
            <h1>
                Watering system
                <IconButton onClick={() => getTrendData()}><CachedIcon /></IconButton>
            </h1>
            {/* {JSON.stringify(props.applicationConfigurations)} */}
            Sensor: {config.sensors || '-'}
            <br/>
            Actuator: {config.actuator || '-'}
            <Grid container spacing={8}>
                <Grid item lg={2} xs={6}>
                    <Card className={classes.root} variant='outlined'>
                    <CardContent>
                        <Typography className={classes.title} variant='h5' component='h2'>
                            Reservoir at {Math.round((currentReservoirLevel.value || 0) * 10)/10}%
                        </Typography>
                        <AngularChart
                            trendValue = {currentReservoirLevel as any}
                            maxValue={100}
                        >
                            <div id='chartdiv'></div>
                        </AngularChart>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={2} xs={6}>
                    <Card
                        className={classes.root}
                        variant='outlined'
                        style={{ minHeight: '251px', height: '100%' }}
                    >
                        <CardContent>
                            <Box>
                                <Typography className={classes.title} variant='h5' component='h2'>
                                    Ground moisture at {78.6}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <TextField id='outlined-basic' label='Target' variant='outlined' value={100}/>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box ml={1}>
                                <Button disabled variant='outlined' color='primary'>Set target</Button>
                            </Box>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item lg={8} xs={12}>
                    <Paper style={{height: '100%'}}>
                        <XYChart
                            trendValues={props.trendValues}
                        >
                            <div id='chartdiv2' style={{ width: '100%', minHeight: '251px', height: '100%' }}>
                            </div>
                        </XYChart>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = (state: { automationReducer: IAutomationState }, ownProps: any): IComponentProps => {

    return {

        applicationConfigurations: state.automationReducer.applicationConfigurations,
        applicationConfigurationsLoading: state.automationReducer.applicationConfigurationsLoading,

        applications: state.automationReducer.applications,
        applicationsLoading: state.automationReducer.applicationsLoading,

        trendValues: state.automationReducer.trendValues,
        trendValuesLoading: state.automationReducer.trendValuesLoading,

        error: state.automationReducer.error,
    };
};

// Maps Redux store actions to component's props.
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {

        getTrendData: (startTime: Moment, endTime: Moment, dataPathName: string) => {
            dispatch({
                type: sagaActions.TRENDVALUES_SAGA,
                payload: {

                    startTime: startTime.format(),
                    endTime: endTime.format(),
                    dataPathName,
                },
            });
        },
        getApplicationById: (id: number) => {
            dispatch({
                type: sagaActions.APPLICATION_BY_ID_SAGA,
                payload: {
                    id,
                },
            });
        },
        getApplicationConfiguration: (id: number) => {
            dispatch({
                type: sagaActions.APPLICATION_CONFIGURATION_SAGA,
                payload: {
                    id,
                },
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWateringSystem);
