import { put, takeEvery, all } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import {
    devicesSuccessAction,
    queryStartedAction,
    APIErrorAction,
} from '../reducers/automationReducer';
import { APIClient, DeviceDto } from '../../services/PhoenixAPIClient';
import { store } from '../store';

// Our worker Saga: will perform the async task
export function* getDevicesAsync(action: any) {

    store.dispatch(queryStartedAction({startedQuery: 'devices'}));

    const client = new APIClient('/api/v1');

    try {

        const devices = yield client.getDevices(undefined);
        yield put(devicesSuccessAction({ devices }));

    } catch (error) {

        yield put(APIErrorAction({ error }));
    }
}

// Watcher Saga
export function* watchDevicesAsync() {
    yield takeEvery(sagaActions.DEVICES_SAGA, getDevicesAsync);
}
