/* Imports */
import React, { useState } from 'react';


import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { TrendValueDto } from '../services/PhoenixAPIClient';
import { GaugeChart } from '@amcharts/amcharts4/charts';

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

function createGrid(ax: any, value: number) {
    const range = ax.axisRanges.create();
    range.value = value;
    range.label.text = '{value}';
}

const { useRef } = React;

export interface IComponentProps {

    trendValue: TrendValueDto;
    maxValue: number;
    children: JSX.Element;
}
/* https://www.amcharts.com/docs/v4/chart-types/gauge-chart/ */
/* https://medium.com/swlh/how-to-use-amcharts-4-with-react-hooks-999a62c185a5 */
export default function AngularChart(props: IComponentProps) {

    const chartRef = useRef<GaugeChart|null>(null);

    /* Init chart on component mount */
    React.useEffect(() => {

        if (!chartRef.current) {

            // create chart
            const chart = am4core.create(props.children.props.id, am4charts.GaugeChart);
            chartRef.current = chart;

            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

            chart.innerRadius = -25;
            const axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
            axis.min = 0;
            axis.max = 100;

            /* Custom ranges: https://www.amcharts.com/docs/v4/tutorials/custom-valueaxis-grid-using-ranges/ */
            axis.renderer.grid.template.disabled = true;
            axis.renderer.labels.template.disabled = true;

            createGrid(axis, 0);
            createGrid(axis, 33);
            createGrid(axis, 67);
            createGrid(axis, 100);

            axis.strictMinMax = true;
            axis.renderer.grid.template.stroke = new am4core.InterfaceColorSet().getFor('background');
            axis.renderer.grid.template.strokeOpacity = 0.3;

            const colorSet = new am4core.ColorSet();

            const range0 = axis.axisRanges.create();
            range0.value = 0;
            range0.endValue = 33;
            range0.axisFill.fillOpacity = 1;
            range0.axisFill.fill = colorSet.getIndex(0);
            range0.axisFill.zIndex = - 1;

            const range1 = axis.axisRanges.create();
            range1.value = 33;
            range1.endValue = 67;
            range1.axisFill.fillOpacity = 1;
            range1.axisFill.fill = colorSet.getIndex(2);
            range1.axisFill.zIndex = -1;

            const range2 = axis.axisRanges.create();
            range2.value = 67;
            range2.endValue = 100;
            range2.axisFill.fillOpacity = 1;
            range2.axisFill.fill = colorSet.getIndex(4);
            range2.axisFill.zIndex = -1;

            const hand = chart.hands.push(new am4charts.ClockHand());

            hand.showValue(0); // TODO: easing
        }
        /* Effect cleaning method */
        return () => {
            chartRef?.current?.dispose();
        };

    }, []);

    // Load data into chart on data changes
    React.useEffect(() => {

        if (chartRef.current) {

            console.log('AngularChart: Deps changed');
            const hand = chartRef.current.hands.values[0];
            hand.showValue(props.trendValue?.value); // TODO: easing
        }

    }, [props.trendValue]);

    return ( <> {props.children} </> );
}
