import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, makeStyles, Typography, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Styles } from '@material-ui/core/styles/withStyles';
import { ApiErrorDto } from '../services/PhoenixAPIClient';

const styles: any = (theme: any) => ({
    root: {
        'margin': 0,
        'border-width': '3px',
        'border-color': 'green',
        'background-color': 'red',
        'padding': theme.spacing(3),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(2),

    }
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

export interface IComponentProps {

    title: string;
    error: ApiErrorDto;
    details: any;
    closeHandler: () => {};
}

export function ErrorModal(props: IComponentProps) {

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        props.closeHandler();
        // setOpen(false);
    };

    const descriptionElementRef = React.useRef<any>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
            >
            <DialogTitle id='scroll-dialog-title'>{props.title}</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    id='scroll-dialog-description'
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    {props.error.message}
                    <br/>
                    {JSON.stringify(props.details, null, 2)}

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Ok
                </Button>
            </DialogActions>
            </Dialog>
        </div>
    );
}
