import { AppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { makeStyles } from '@material-ui/core/styles';
import SideDrawer from './SideDrawer';
import { Link } from 'react-router-dom';
import { logOutAction } from '../redux/reducers/authReducer';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
}));

interface IComponentState {

    open: boolean;
}

interface IDispatchProps {

    logOut: () => void;
}

function TopBar(props: IDispatchProps) {

    const classes = useStyles();

    const [state, setState] = React.useState<IComponentState>({
        open: false,
    });

    const openDrawer = () => {
        setState({ ...state, open: true });
    };

    const closeDrawer = () => {
        setState({ ...state, open: false });
    };

    return (
        <>
        <AppBar position='static'>
            <Toolbar>
                <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='menu'>
                <MenuIcon onClick={openDrawer}/>
                </IconButton>
                <Typography variant='h6' className={classes.title}>
                {/* News */}
                </Typography>
                <Button
                    color='inherit'
                    onClick={() => props.logOut()}
                >
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
        <SideDrawer closeDrawer={closeDrawer} open={state.open}></SideDrawer>
        </>
    );
}

function mapDispatchToProps(dispatch: any): IDispatchProps {
    return {
        logOut: () => {

            dispatch(logOutAction());
        }
    };
}

export default connect(null, mapDispatchToProps, /* { push } */)(TopBar);
