import { put, takeEvery, all } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import {
    applicationsSuccessAction,
    devicesSuccessAction,
    dataPathsSuccessAction,
    trendValuesSuccessAction,
    applicationConfigurationsSuccessAction,
    queryStartedAction,
    APIErrorAction,
} from '../reducers/automationReducer';
import { APIClient, TrendValueDto } from '../../services/PhoenixAPIClient';
import { store } from '../store';

export function* getTrendValuesAsync(action: any): any {

    store.dispatch(queryStartedAction({startedQuery: 'trendValues'}));

    const client = new APIClient('/api/v1');

    try {

        const trendValues = yield client.getTrendValues(
            action.payload.dataPathName,
            action.payload.startTime,
            action.payload.endTime,
        );

        yield put(trendValuesSuccessAction({ trendValues }));

    } catch (error) {

        yield put(APIErrorAction({ error }));
    }
}

// Watcher Saga
export default function* watchTrendValuesAsync() {
    yield takeEvery(sagaActions.TRENDVALUES_SAGA, getTrendValuesAsync);
}
