
import React, { useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export interface IComponentProps {
    setSelectedComboBoxValue: (selection: { title: string }) => void;
    comboBoxValues: Array<{ title: string }>;
    selectedComboBoxValue: { title: string };
}

/* https://material-ui.com/components/autocomplete/ */
export function ComboBox(props: IComponentProps) {

    return (
      <Autocomplete
        id='combo-box-demo'
        onChange={(event, newValue) => props.setSelectedComboBoxValue(newValue as any)}
        value={props.selectedComboBoxValue || (props.comboBoxValues ? props.comboBoxValues[0] : null)}
        options={props.comboBoxValues}
        getOptionLabel={(option: any) => option.title}
        style={{ width: 300 }}
        renderInput={(params: any) => <TextField {...params} label='Data path' variant='outlined' />}
      />
    );
}
