import React from 'react';
import './App.css';

import MainView from './views/MainView';

import { connect } from 'react-redux';
import { history } from './redux/store';

// https://reacttraining.com/react-router/web/guides/quick-start
import {
    // BrowserRouter as Router,
    Switch,
    Route,
    /* Link, */
} from 'react-router-dom';

import Link from '@material-ui/core/Link';

import LogoutView from './views/LogoutView';
import LoginView from './views/LoginView';

import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { loginStartAction } from './redux/reducers/authReducer';
import { EnhancedStore } from '@reduxjs/toolkit';
import { ConnectedRouter } from 'connected-react-router';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

interface IComponentProps {

    store: EnhancedStore;
}



// TODO: protected routes https://tylermcginnis.com/react-router-protected-routes-authentication/
function App(props: IComponentProps) {

    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
          createMuiTheme({
            palette: {
              type: 'dark',
            },
          }),
        [ /* prefersDarkMode */ ],
      );
    return (
        <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path='/login'>
                        <LoginView/>
                    </Route>
                    <Route exact path='/logout'>
                        <LogoutView/>
                    </Route>
                    <Route path='/'>
                        <MainView/>
                    </Route>
                </Switch>
            </ConnectedRouter>
        </ThemeProvider>
    );
}

/*
const mapStateToProps = (state: EnhancedStore): IComponentProps => {
    return { store: state };
};
function mapDispatchToProps(dispatch: any) {

    return {

        deleteQuote: (quote: Quote) => {

            dispatch(deleteQuote(quote));
        }
    };
}
 */
// https://react-redux.js.org/api/connect
// export default App;
export default connect(null, null)(App);
