import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IAutomationErrorState, IDevicesState } from '../redux/reducers/automationReducer';
import { sagaActions } from '../redux/sagas/sagaActions';
import { DeviceDto } from '../services/PhoenixAPIClient';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

type IComponentProps = IDevicesState & IAutomationErrorState;
interface IDispatchProps {

    getDevices: () => void;
}

type DevicesViewProps = IComponentProps & IDispatchProps;

function DevicesView(props: DevicesViewProps) {

    const classes = useStyles();

    // Get devices if not present already
    if (props.devices == null && !props.devicesLoading && props.error == null) {
        props.getDevices();
    }

    return (
        <>
            <h1>Devices View</h1>
            <IconButton onClick={props.getDevices}><CachedIcon /></IconButton>
            {/* Devices: {JSON.stringify(props.devices)} */}
            {props.devicesLoading ? <LinearProgress /> : null }
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                <TableRow>
                    <TableCell>Device Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Interface</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.devices?.map((device: DeviceDto) => (
                    <TableRow key={device.config?.deviceId}>
                    <TableCell>{device.config?.deviceId}</TableCell>
                    <TableCell component='th' scope='row'>
                        {device.config?.deviceName}
                    </TableCell>
                    <TableCell>{device.status}</TableCell>
                    <TableCell>{device.config?.interfaceName || '(none)'}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </>
    );
}

const mapStateToProps = (state: any, ownProps: any): IComponentProps => {

    return {
        devices: state.automationReducer.devices,
        devicesLoading: state.automationReducer.devicesLoading,
        error: state.automationReducer.error,
    };
};

// Maps Redux store actions to component's props.
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {
        getDevices: () => {
            dispatch({ type: sagaActions.DEVICES_SAGA });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesView);
