import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import TimelineIcon from '@material-ui/icons/Timeline';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MemoryIcon from '@material-ui/icons/Memory';
import MailIcon from '@material-ui/icons/Mail';
import { DeviceHub } from '@material-ui/icons';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SpeedIcon from '@material-ui/icons/Speed';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export interface IComponentProps {

    open: boolean;
    closeDrawer: () => void;
}

// Navigation with history hooks in react-router-dom
// https://ultimatecourses.com/blog/programmatically-navigate-react-router
export default function SideDrawer(props: IComponentProps) {

    const classes = useStyles();
    const history = useHistory();

    const onClickHandler = (navTarget: string) => {

        // Redirects to other pages:
        if (navTarget === '/swagger') {

            window.location.href = '/swagger';
            return;
        }

        history.push(navTarget);
        props.closeDrawer();
    };

    const drawerListItems = () => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: false,
            })}
            role='presentation'
        >
            <List>
                <ListItem button key={'Dashboards'} onClick={() => onClickHandler('/dashboards')}>
                    <ListItemIcon><SpeedIcon /></ListItemIcon>
                        <ListItemText primary={'Dashboards'}/>
                </ListItem>
                <ListItem button key={'Applications'} onClick={() => onClickHandler('/applications')}>
                    <ListItemIcon><AccountTreeIcon /></ListItemIcon>
                        <ListItemText primary={'Applications'}/>
                </ListItem>
                <ListItem button key={'Devices'} onClick={() => onClickHandler('/devices')}>
                    <ListItemIcon><MemoryIcon /></ListItemIcon>
                    <ListItemText primary={'Devices'} />
                </ListItem>
                <ListItem button key={'Networking'} onClick={() => onClickHandler('/networking')}>
                    <ListItemIcon><DeviceHubIcon /></ListItemIcon>
                    <ListItemText primary={'Networking'} />
                </ListItem>
                <ListItem button key={'Data'} onClick={() => onClickHandler('/data')}>
                    <ListItemIcon><TimelineIcon /></ListItemIcon>
                    <ListItemText primary={'Data'} />
                </ListItem>
                <ListItem button key={'Settings'} onClick={() => onClickHandler('/settings')}>
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText primary={'Settings'} />
                </ListItem>
                <ListItem button key={'MenuBookIcon'} onClick={() => onClickHandler('/swagger')}>
                    <ListItemIcon><MenuBookIcon /></ListItemIcon>
                    <ListItemText primary={'Swagger UI'} />
                </ListItem>
            </List>
            <Divider/>
        </div>
    );

    return (
        <div>
            <Drawer anchor={'left'} open={props.open} onClose={props.closeDrawer}>
                {drawerListItems()}
            </Drawer>
        </div>
    );
}
