import { put, takeEvery, all } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import {
    devicesSuccessAction,
    queryStartedAction,
    APIErrorAction,
    dataPathsSuccessAction,
} from '../reducers/automationReducer';
import { APIClient, DeviceDto } from '../../services/PhoenixAPIClient';
import { store } from '../store';

export function* getDataPathsAsync(action: any) {

    store.dispatch(queryStartedAction({startedQuery: 'dataPaths'}));

    const client = new APIClient('/api/v1');

    try {

        const dataPaths = yield client.getDataPaths();
        yield put(dataPathsSuccessAction({ dataPaths }));

    } catch (error) {

        yield put(APIErrorAction({ error }));
    }
}

// Watcher Saga
export function* watchDataPathsAsync() {
    yield takeEvery(sagaActions.DATAPATHS_SAGA, getDataPathsAsync);
}
