import React, { ChangeEvent, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Paper } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import ReCAPTCHA from 'react-google-recaptcha';

import config from '../appConfig.json';

import './LoginView.css';
import { sagaActions } from '../redux/sagas/sagaActions';
import { connect } from 'react-redux';
import { APIErrorAction, IAuthState } from '../redux/reducers/authReducer';
import { useHistory } from 'react-router-dom';
import { ErrorModal } from '../components/ErrorModal';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='#'>
        EPr
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface IComponentProps {

    authState: IAuthState;
}

interface IDispatchProps {

    login: any;
    isBanned: any;
    clearAPIError: any;
}

function LoginView(props: IComponentProps & IDispatchProps) {

    const [ email, setEmail ] = useState<string|null>(null);
    const [ password, setPassword ] = useState<string|null>(null);
    const [ captchaValue, setCaptchaValue ] = useState<string|null>(null);

    React.useEffect(() => {

        props.isBanned();
    }, []);

    const classes = useStyles();
    console.log('Auto-login:', config.autoLogin);
    const history = useHistory();

    if (props.authState.token) {

        history.push('/');
    }

    function showAuthError() {

        if (props.authState.error) {

            return (
                <ErrorModal
                    title={'Authentication failed'}
                    error={props.authState.error}
                    details={props.authState.error.validationErrors}
                    closeHandler={() => props.clearAPIError()}
                />
            );
        }
    }

    function handleSubmit(evt: React.FormEvent) {

        evt.preventDefault();
        props.login({
            email,
            password,
            gRecaptchaResponse: captchaValue || '',
            // remember me
        });
    }

    function onChange(value: any) {
        console.log('Captcha value:', value);
        setCaptchaValue(value)
    }
    return (
        <Container component='main' maxWidth='xs'>
            {showAuthError()}
            <CssBaseline />
            <Paper style={{ padding: 20, marginTop: 30, opacity: 0.9 }}>
                <div className={classes.paper}>
                    <img className='logo' src={'./Logo_blue.png'} alt='Logo' />
                    <Typography component='h2' variant='h5'>
                    Sign in
                    </Typography>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handleSubmit}>
                        <TextField
                            value = {email}
                            onChange={(evt: any) => setEmail(evt.target.value)}
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            id='email'
                            label='Email Address'
                            name='email'
                            autoComplete='email'
                            autoFocus
                        />
                        <TextField
                            value = {password}
                            onChange={(evt: any) => setPassword(evt.target.value)}
                            variant='outlined'
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Password'
                            type='password'
                            id='password'
                            autoComplete='current-password'
                        />
                        {props.authState.isBanned ?
                            <ReCAPTCHA
                                sitekey='6LctzcsUAAAAAMXwAwLs7pvrU4jtHpAEAo33p008'
                                onChange={onChange}
                            /> : null
                        }
                        <FormControlLabel
                            control={<Checkbox value='remember' color='primary' />}
                            label='Remember me'
                        />
                        {props.authState.loading ? <LinearProgress /> : null }
                        <Button
                            type='submit'
                            disabled={props.authState.loading}
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                    </form>
                </div>
            <Box mt={3}>
                <Copyright />
            </Box>
            </Paper>
        </Container>
    );
}

const mapStateToProps = (state: any): IComponentProps => {
    return { authState: state.authReducer };
};

function mapDispatchToProps(dispatch: any): IDispatchProps {
    return {
        login: (credentials: any) => {
            dispatch({ type: sagaActions.AUTHENTICATE_SAGA, payload: credentials });
        },
        isBanned: () => {
            dispatch({ type: sagaActions.ISBANNED_SAGA, payload: null });
        },
        clearAPIError: () => {

            dispatch(APIErrorAction({}));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
