import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { sagaActions } from '../redux/sagas/sagaActions';
import { ApplicationDto, IApplicationDto } from '../services/PhoenixAPIClient';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

interface IComponentProps {

    applications: ApplicationDto[];
}

interface IDispatchProps {

    getApplications: () => void;
}

type ApplicationsViewProps = IComponentProps & IDispatchProps;

function ApplicationsView(props: ApplicationsViewProps) {

    const classes = useStyles();

    return (
        <>
            <h1>Applications View</h1>
            <IconButton onClick={props.getApplications}><CachedIcon /></IconButton>
            {/* Applications: {JSON.stringify(props.applications)} */}
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                <TableRow>
                    <TableCell>#Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Errors</TableCell>
                    <TableCell>Last Exec</TableCell>
                    <TableCell>AutoInit</TableCell>
                    <TableCell>AutoStart</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Execution Cycle (ms)</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.applications?.map((app: ApplicationDto) => (
                    <TableRow key={app.config?.id}>
                    <TableCell>{app.config?.id}</TableCell>
                    <TableCell component='th' scope='row'>
                        {app.config?.applicationName}
                    </TableCell>
                    <TableCell>{app.status}</TableCell>
                    <TableCell>{app.state?.error || 'No errors'}</TableCell>
                    <TableCell>
                        {
                            !app.state?.lastExecution ||
                             app.state?.lastExecution?.getFullYear() < 2000
                            ? 'never'
                            : moment(app.state?.lastExecution).format('DD.MM.YY HH:mm:ss')
                        }
                        <br></br>
                        {
                            !app.state?.lastExecution ||
                            app.state?.lastExecution?.getFullYear() < 2000
                            ? ''
                            : '(' + moment(app.state?.lastExecution).fromNow() + ')'
                        }
                        </TableCell>
                    <TableCell>{app.config?.autoInitialize?.toString() || 'default'}</TableCell>
                    <TableCell>{app.config?.autoStart ? 'true' : 'false'}</TableCell>
                    <TableCell>{JSON.stringify(app.config?.type)}</TableCell>
                    <TableCell>{app.config?.executionCycleMS}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </>
    );
}

const mapStateToProps = (state: any, ownProps: any): IComponentProps => {
    return { applications: state.automationReducer.applications };
};

// Maps Redux store actions to component's props.
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {
        getApplications: () => {
            dispatch({ type: sagaActions.APPLICATIONS_SAGA });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsView);
