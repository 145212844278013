import { createSlice  } from '@reduxjs/toolkit';
import { ApiErrorDto } from '../../services/PhoenixAPIClient';

export interface IAuthState {
    username: string|null;
    token: string|null;
    error: ApiErrorDto|null;
    loading: boolean;
    isBanned: boolean;
}

const initialState: IAuthState = {
    username: '',
    token: localStorage.getItem('authToken'),
    isBanned: false,
    error: null,
    loading: false,
};

/* Examples: https://www.valentinog.com/blog/redux/#modern-redux-with-redux-toolkit-createslice */
/* Examples: https://redux-toolkit.js.org/api/createSlice */
const authSlice = createSlice({
    name: 'auth',
    reducers: {

        queryStarted: (state: IAuthState, action: any): void => {

            state.loading = true;
        },
        isBannedSuccess: (state: IAuthState, action: any): void => {

            state.isBanned = action.payload.isBannedData;
        },
        loginSuccess: (state: IAuthState, action: any): void => {

            // Note: createSlice allows safe mutate of state
            state.loading = false;
            state.token = action.payload.authData.token;
            localStorage.setItem('authToken', state.token as any);
        },
        logOut: (state: IAuthState): void => {

            // Note: createSlice allows safe mutate of state
            state.token = '';
            localStorage.removeItem('authToken');
        },
        APIError: (state: IAuthState, action: any): void => {
            state.loading = false;
            state.error = action.payload.error;
        },
    },
    initialState,
});

const {
    loginSuccess,
    isBannedSuccess,
    logOut,
    APIError,
    queryStarted,
} = authSlice.actions;
const authReducer = authSlice.reducer;

export {
    loginSuccess as loginSuccessAction,
    isBannedSuccess as isBannedSuccessAction,
    logOut as logOutAction,
    APIError as APIErrorAction,
    queryStarted as queryStartedAction,
    authReducer,
};
