import { put, takeEvery, all } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import { loginSuccessAction, isBannedSuccessAction, queryStartedAction } from '../reducers/authReducer';
import { APIClient, AuthenticateCommand } from '../../services/PhoenixAPIClient';
import { APIErrorAction } from '../reducers/authReducer';
import { store } from '../store';

// Delay for simulating API call
const delay = (ms: number) => new Promise((res: any) => setTimeout(res, ms));

// Our worker Saga: will perform the async task
export function* authenticateAsync(action: any): any {

    const client = new APIClient('/api/v1');

    try {

        store.dispatch(queryStartedAction({}));
        const authenticateCmd: AuthenticateCommand = new AuthenticateCommand({

            email: action.payload.email,
            username: action.payload.email,
            password: action.payload.password,
            gRecaptchaResponse: action.payload.gRecaptchaResponse,
        });
        const authData = yield client.authenticateUser(authenticateCmd);
        yield put(loginSuccessAction({ authData }));

    } catch (error) {

        yield put(APIErrorAction({ error }));

        if (error?.code === 'WRONG_USERNAME_OR_PASSWORD') {

            // Check if user is banned:
            store.dispatch({ type: sagaActions.ISBANNED_SAGA, payload: null });
        }
    }
}

// Our worker Saga: will perform the async task
export function* isBannedAsync(action: any): any {

    const client = new APIClient('/api/v1');

    try {

        const isBannedData = yield client.getIsBanned();
        yield put(isBannedSuccessAction({ isBannedData }));

    } catch (error) {

        yield put(APIErrorAction({ error }));
    }
}

// Our watcher Saga: spawn a new authenticateAsync task on each sagaActions.AUTHENTICATE_SAGA
export function* watchAuthenticateAsync() {
    yield takeEvery(sagaActions.AUTHENTICATE_SAGA, authenticateAsync);
    yield takeEvery(sagaActions.ISBANNED_SAGA, isBannedAsync);
}
