// https://redux-toolkit.js.org/tutorials/advanced-tutorial

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { authReducer } from './reducers/authReducer';
import { automationReducer } from './reducers/automationReducer';
import { createBrowserHistory } from 'history';
import { combineReducers, Middleware } from 'redux';
import { connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

// import authSaga from './sagas/authSaga';
// import automationSaga from './sagas/automationSaga';
import rootSaga from './sagas/rootSaga';
// import { NavigationDataQuery } from './middleware/navigationDataQuery';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const store = configureStore({
    middleware: [ ...getDefaultMiddleware({ thunk: false }), sagaMiddleware/* , NavigationDataQuery */ ],
    reducer: combineReducers({
        router: connectRouter(history),
        authReducer,
        automationReducer,
    }),
});

// Start Saga(s)
sagaMiddleware.run(rootSaga);

export { store };
