import { put, takeEvery, all } from 'redux-saga/effects';
import { sagaActions } from './sagaActions';
import {
    applicationsSuccessAction,
    devicesSuccessAction,
    dataPathsSuccessAction,
    trendValuesSuccessAction,
    applicationConfigurationsSuccessAction,
    queryStartedAction,
    APIErrorAction,
} from '../reducers/automationReducer';
import { APIClient, DeviceDto } from '../../services/PhoenixAPIClient';
import { store } from '../store';

export function* getApplicationByIdAsync(action: any): any {

    store.dispatch(queryStartedAction({startedQuery: 'applications'}));

    const client = new APIClient('/api/v1');

    try {

        const application = yield client.getApplication(action.payload.id);
        yield put(applicationsSuccessAction({ applications : [application] }));

    } catch (error) {

        yield put(APIErrorAction({ error }));
    }
}

export function* getApplicationsAsync(action: any): any {

    store.dispatch(queryStartedAction({startedQuery: 'applications'}));

    const client = new APIClient('/api/v1');

    try {

        const applications = yield client.getApplications();
        yield put(applicationsSuccessAction({ applications }));

    } catch (error) {

        yield put(APIErrorAction({ error }));
    }
}

export function* getApplicationConfigurationAsync(action: any) {

    store.dispatch(queryStartedAction({startedQuery: 'applicationConfigurations'}));

    const client = new APIClient('/api/v1');

    try {

        const applicationConfigs = yield client.getApplicationConfiguration(action.payload.id);
        yield put(applicationConfigurationsSuccessAction({ applicationConfigurations: applicationConfigs }));

    } catch (error) {

        yield put(APIErrorAction({ error }));
    }
}

// Watcher Saga
export function* watchApplicationsAsync() {
    yield takeEvery(sagaActions.APPLICATIONS_SAGA, getApplicationsAsync);
    yield takeEvery(sagaActions.APPLICATION_BY_ID_SAGA, getApplicationByIdAsync);
    yield takeEvery(sagaActions.APPLICATION_CONFIGURATION_SAGA, getApplicationConfigurationAsync);
}
