import { all } from 'redux-saga/effects';
import { watchDevicesAsync } from './devicesSaga';
import { watchApplicationsAsync } from './applicationsSaga';
import watchTrendValuesAsync from './trendValuesSaga';
import { watchDataPathsAsync } from './dataPathsSaga';

export default function*(): any {
    yield all([
        watchDevicesAsync(),
        watchApplicationsAsync(),
        watchTrendValuesAsync(),
        watchDataPathsAsync(),
    ]);
}
