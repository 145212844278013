import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';


import moment from 'moment';
import CachedIcon from '@material-ui/icons/Cached';
import { Moment } from 'moment';

import { IDataPathsState, ITrendValuesState, IAutomationErrorState } from '../redux/reducers/automationReducer';
import { AnyAction, Dispatch } from 'redux';
import { sagaActions } from '../redux/sagas/sagaActions';
import { connect } from 'react-redux';
import { DataPathDto } from '../services/PhoenixAPIClient';
import { Grid, IconButton, Typography, Slider } from '@material-ui/core';

import { XYChart } from '../components/XYChart';
import { DatePicker } from '../components/DatePicker';
import { ComboBox } from '../components/ComboBox';

import './DataView.css';

type IComponentProps =
    IDataPathsState &
    ITrendValuesState &
    IAutomationErrorState;

interface IDispatchProps {

    getDataPaths: () => void;
    getTrendData: (startTime: Moment, endTime: Moment, dataPathName: string) => void;
}

type DataViewProps = IComponentProps & IDispatchProps;

function valuetext(value: number) {
    return `${value}s`;
}

const marks = [
    {
        value: 0,
        label: 'OFF',
    },
    {
      value: 25,
      label: '10s',
    },
    {
      value: 50,
      label: '1m',
    },
    {
      value: 75,
      label: '10m',
    },
    {
      value: 100,
      label: '1h',
    },
];

function DataView(props: DataViewProps) {

    const comboBoxValues = props?.dataPaths?.map((dataPath: DataPathDto) => {

        return { title: dataPath.name || '(no name)' };
    }) || [];

    // https://blog.logrocket.com/a-guide-to-usestate-in-react-ecb9952e406c/
    const [selectedDate, setSelectedDate] = useState(moment());
    const [selectedDataPath, setSelectedDataPath] = useState(comboBoxValues?.[0]);

    // Get dataPaths if not present already
    if ((props.dataPaths === null || props.dataPaths.length === 0)  && props.error == null) {
        props.getDataPaths();
    }

    const getTrendData = () => {

        props.getTrendData(
            selectedDate.hour(0).minute(0).second(0),
            moment(selectedDate).add(1, 'days'),
            selectedDataPath.title,
        );
    };



    if (selectedDataPath === null && comboBoxValues != null && comboBoxValues.length > 0) {

        setSelectedDataPath(comboBoxValues[0]);
    }

    return (
        <Container maxWidth='xl' style={{ height: '100%'}}>
            <h1>Data View</h1>
            <Grid container spacing={3}>
                <Grid item xl={4} xs={12}>
                    <ComboBox
                        comboBoxValues={comboBoxValues}
                        setSelectedComboBoxValue={setSelectedDataPath}
                        selectedComboBoxValue={selectedDataPath}
                    ></ComboBox>
                </Grid>
                <Grid item xl={4} xs={6}>
                    <DatePicker date={selectedDate} setSelectedDate={setSelectedDate}></DatePicker>
                </Grid>
                <Grid item xl={4} xs={4}>
                <Typography id='discrete-slider' gutterBottom>
                    Refresh
                    <IconButton onClick={getTrendData}>
                        <CachedIcon />
                    </IconButton>
                </Typography>
                <Slider
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    aria-labelledby='discrete-slider'
                    valueLabelDisplay='off'
                    step={null}
                    marks={marks}
                    min={0}
                    max={100}
                />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <XYChart trendValues={props.trendValues}>
                            <div id='chartdiv' style={{ width: '100%', height: '500px' }}>
                            </div>
                        </XYChart>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

const mapStateToProps = (state: any, ownProps: any): IComponentProps => {

    return {
        trendValues: state.automationReducer.trendValues,
        trendValuesLoading: state.automationReducer.trendValuesLoading,
        dataPaths: state.automationReducer.dataPaths,
        dataPathsLoading: state.automationReducer.dataPathsLoading,
        error: state.automationReducer.error,
    };
};

// Maps Redux store actions to component's props.
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IDispatchProps => {
    return {
        getDataPaths: () => {
            dispatch({ type: sagaActions.DATAPATHS_SAGA });
        },
        getTrendData: (startTime: Moment, endTime: Moment, dataPathName: string) => {

            dispatch({
                type: sagaActions.TRENDVALUES_SAGA,
                payload: {

                    startTime: startTime.format(),
                    endTime: endTime.format(),
                    dataPathName,
                },
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataView);
