import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export interface IComponentProps {
    setSelectedDate: (date: any) => void;
    date: Moment;
}

/* TODO: rounding is not working? */
function roundToMidnight(date: Moment) {

    return date.hour(0).minute(0).second(0);
}

export function DatePicker(props: IComponentProps) {

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant='inline'
                format='DD.MM.yyyy'
                margin='dense'
                id='date-picker-inline'
                label='Data date'
                value={props.date}
                onChange={(date) => props.setSelectedDate(roundToMidnight(moment(date)))}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
}
