import { all } from 'redux-saga/effects';
import { watchAuthenticateAsync } from './authSaga';
import { watchDevicesAsync } from './devicesSaga';
import automationSaga from './automationSaga';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        watchAuthenticateAsync(),
        automationSaga(),
    ]);
}
