import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Redux
import { store } from './redux/store';
import { Provider } from 'react-redux';
// Redux

// NOTE: Redux events fire twice with StrictMode
// https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
ReactDOM.render(
    <>
        <React.StrictMode>
        {/* // FIXME: enable React.StrictMode
        after resolved this: https://github.com/ant-design/ant-design/issues/22493 */}
            {/* https://redux.js.org/basics/usage-with-react#passing-the-store */}
            <Provider store={ store }>
                <App store={ store }/>
            </Provider>
        </React.StrictMode>
    </>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
