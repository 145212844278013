import { createSlice  } from '@reduxjs/toolkit';
import {
    DeviceDto,
    ApplicationDto,
    DataPathDto,
    TrendValueDto,
    ApplicationConfigurationDto,
    ApplicationTypeDto,
    Sequencer_ApplicationConfigurationDto,
} from '../../services/PhoenixAPIClient';

export interface IDevicesState {

    devices: DeviceDto[]|null;
    devicesLoading: boolean;
}
export interface IApplicationsState {

    applications: ApplicationDto[]|null;
    applicationConfigurations: ApplicationConfigurationDto[]|any|null;
    applicationsLoading: boolean;
    applicationConfigurationsLoading: boolean;
}

export interface IDataPathsState {

    dataPaths: DataPathDto[]|null;
    dataPathsLoading: boolean;
}

export interface ITrendValuesState {

    trendValues: TrendValueDto[];
    trendValuesLoading: boolean;
}

export interface IAutomationErrorState {

    error: any;
}

export type IAutomationState =
    IDevicesState &
    IApplicationsState &
    IDataPathsState &
    IAutomationErrorState &
    ITrendValuesState;

const initialState: IAutomationState = {

    applications: null,
    applicationsLoading: false,

    applicationConfigurationsLoading: false,
    applicationConfigurations: [],

    devices: null,
    devicesLoading: false,

    dataPaths: [],
    dataPathsLoading: false,

    trendValues: [],
    trendValuesLoading: false,

    error: null,
};

const automationSlice = createSlice({
    name: 'automation',
    reducers: {

        /* Generic query active Action */

        queryStarted: (state: IAutomationState, action: any): void => {

            switch (action.payload.startedQuery) {

                case 'devices':
                    state.devicesLoading = true;
                    break;

                case 'applications':
                    state.applicationsLoading = true;
                    break;

                case 'applicationConfigurations':
                    state.applicationConfigurationsLoading = true;
                    break;

                case 'dataPaths':
                    state.dataPathsLoading = true;
                    break;

                case 'trendValues':
                    state.trendValuesLoading = true;
                    break;

                default:
                    console.error('Unknown started query', action.payload.startedQuery);
            }
        },

        /* Data query started */
        /*
        devicesQueryStarted: (state: IAutomationState, action: any): void => {

            state.devicesLoading = true;
        },
        applicationsQueryStarted: (state: IAutomationState, action: any): void => {

            state.applicationsLoading = true;
        },
        */

        /* Data query completed */

        devicesSuccess: (state: IAutomationState, action: any): void => {

            state.devicesLoading = false;
            state.devices = action.payload.devices;
        },
        applicationsSuccess: (state: IAutomationState, action: any): void => {

            state.applicationsLoading = false;
            state.applications = action.payload.applications;
        },
        applicationConfigurationsSuccess: (state: IAutomationState, action: any): void => {

            state.applicationConfigurationsLoading = false;
            state.applicationConfigurations = [];
            action.payload.applicationConfigurations.forEach((conf: ApplicationConfigurationDto) => {

                let hydratedConf = null;
                switch (conf.type) {

                    case ApplicationTypeDto.SEQUENCER:

                        hydratedConf = new Sequencer_ApplicationConfigurationDto();
                        break;
                    default:

                        hydratedConf = new ApplicationConfigurationDto();
                }

                hydratedConf.init(conf);
                state.applicationConfigurations.push(hydratedConf);
            });
        },

        dataPathsSuccess: (state: IAutomationState, action: any): void => {

            state.dataPathsLoading = false;
            state.dataPaths = action.payload.dataPaths;
        },
        trendValuesSuccess: (state: IAutomationState, action: any): void => {

            state.trendValuesLoading = false;
            state.trendValues = action.payload.trendValues;
        },
        APIError: (state: IAutomationState, action: any): void => {

            state.error = action.payload;
        },
    },
    initialState,
});

const {
    queryStarted,
    devicesSuccess,
    applicationsSuccess,
    dataPathsSuccess,
    trendValuesSuccess,
    APIError,
    applicationConfigurationsSuccess,
} = automationSlice.actions;
const automationReducer = automationSlice.reducer;

export {
    queryStarted as queryStartedAction,
    devicesSuccess as devicesSuccessAction,
    applicationsSuccess as applicationsSuccessAction,
    dataPathsSuccess as dataPathsSuccessAction,
    trendValuesSuccess as trendValuesSuccessAction,
    applicationConfigurationsSuccess as applicationConfigurationsSuccessAction,
    APIError as APIErrorAction,
    automationReducer,
};
