import React, { useState } from 'react';

import { TrendValueDto } from '../services/PhoenixAPIClient';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import moment from 'moment';

am4core.useTheme(am4themes_animated);

const { useRef } = React;

export interface IComponentProps {

    /* TODO: support multiple trends */
    trendValues: TrendValueDto[];
    children: JSX.Element;
}

/* AMCharts with React */
/* https://medium.com/swlh/how-to-use-amcharts-4-with-react-hooks-999a62c185a5 */
export const XYChart = (props: IComponentProps) => {

    const chartRef = useRef<any>(null);

    /* Init chart on component mount */
    React.useEffect(() => {

        if (!chartRef.current) {

            console.log('XYChart: creating chart');
            const chart: am4charts.XYChart = am4core.create(props.children.props.id, am4charts.XYChart);
            chartRef.current = chart;

            chart.paddingRight = 20;

            chart.legend = new am4charts.Legend();

            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.grid.template.location = 0;

            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.minWidth = 35;

            const series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = 'date';
            series.dataFields.valueY = 'value';

            series.tooltipText = '{valueY.value}';
            chart.cursor = new am4charts.XYCursor();

            const scrollbarX = new am4charts.XYChartScrollbar();
            scrollbarX.series.push(series);
            chart.scrollbarX = scrollbarX;
        }

        /* Effect cleaning method */
        return () => {
            chartRef?.current?.dispose();
        };

    }, []);

    // Load data into chart on data changes
    React.useEffect(() => {

        console.log('XYChart: Deps changed');
        if (chartRef.current) {

            const data = props.trendValues?.map((trendValue: TrendValueDto) => {

                return {
                    date: moment(trendValue.timeStamp).toDate(),
                    value: (trendValue.value as any) === 'unknown' ? 0 : trendValue.value,
                };
            });
            chartRef.current.data = data;
        }
    }, [props.trendValues]);

    // Handle component unmounting, dispose chart
/*     React.useEffect(() => {
        return () => {
            chartRef?.current?.dispose();
        };
    }, []); */
    return ( <> {props.children} </> );
}
