import { Redirect } from 'react-router-dom';
import React from 'react';
import { logOutAction } from '../redux/reducers/authReducer';
import { connect } from 'react-redux';

const LogoutView  = (props: any) => {

    props.logout();
    return (<Redirect to='/login'/>);
};

const mapStateToProps = (state: any): any => {
    return { authState: state.authReducer };
};

function mapDispatchToProps(dispatch: any): any {
    return {
        logout: () => {
            dispatch(logOutAction());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutView);
